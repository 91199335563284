* {
    box-sizing: border-box;
}

html * {
    font-family: Asap;
}

body {
    background-color: #F2F4F6;
}