@font-face {
    font-family: "Asap";
    src: url("../fonts/Asap/Asap-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-style: italic;
    src: url("../fonts/Asap/Asap-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: bold;
    src: url("../fonts/Asap/Asap-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: bold;
    font-style: italic;
    src: url("../fonts/Asap/Asap-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: 500;
    src: url("../fonts/Asap/Asap-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/Asap/Asap-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: 600;
    src: url("../fonts/Asap/Asap-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/Asap/Asap-SemiBoldItalic.ttf") format("truetype");
}